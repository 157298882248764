<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              v-model="tableUnits.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddFormUnit"
          >
            Add Unit
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="tableUnits"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableUnits.perPage"
        :current-page="tableUnits.currentPage"
        :items="myProvider"
        :fields="tableUnits.fields"
        :sort-by.sync="tableUnits.sortBy"
        :sort-desc.sync="tableUnits.sortDesc"
        :sort-direction="tableUnits.sortDirection"
        :filter="tableUnits.filter"
        :filter-included-fields="tableUnits.filterOn"
      >
        <template #cell(index)="row">
          {{ tableUnits.currentPage * tableUnits.perPage - tableUnits.perPage + (row.index + 1) }}
        </template>

        <template #cell(active)="row">
          {{ row.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell(unit_name)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditFormUnit(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableUnits.perPage"
              :options="tableUnits.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableUnits.currentPage"
            :total-rows="tableUnits.totalRows"
            :per-page="tableUnits.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-unit"
      scrollable
      no-close-on-backdrop
      :title="modalUnitTitle"
      @ok="onValidateFormUnit"
    >
      <ValidationObserver
        ref="formUnit"
      >
        <form
          novalidate
          @submit.prevent
        >
          <ValidationProvider
            #default="{ errors }"
            name="unit name"
            vid="unit_name"
            rules="required|max:100"
          >
            <b-form-group>
              <label
                for="unit_name"
              >
                <strong>
                  Unit
                </strong>
              </label>
              <b-input
                id="unit_name"
                v-model="unit.unit_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter unit name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="unit.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
              switch
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminUnitService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminUnits',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Units'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      unit: {
        id: null,
        unit_name: null,
        active: 0
      },
      tableUnits: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'unit_name', label: 'unit', sortable: true },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ]
      }
    }
  },

  computed: {
    modalUnitTitle () {
      return this.state.editing ? 'Edit Unit' : 'Add Unit'
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async myProvider (ctx) {
      return await AdminUnitService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter
        })
      ).then(({ data }) => {
        this.tableUnits.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    onShowAddFormUnit () {
      this.state.editing = false
      this.unit.id = null
      this.unit.unit_name = null
      this.unit.active = 1
      this.$bvModal.show('modal-unit')
    },

    onShowEditFormUnit (unit) {
      this.state.editing = true
      this.unit.id = unit.id
      this.unit.unit_name = unit.unit_name
      this.unit.active = unit.active
      this.$bvModal.show('modal-unit')
    },

    async onValidateFormUnit (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formUnit.validate().then(async success => {
        if (success) {
          let text = 'Do you really want to create unit?'

          if (this.state.editing) {
            text = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutFormUnit()
              } else {
                return this.onPostFormUnit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onPostFormUnit () {
      return new Promise(resolve => {
        AdminUnitService.post(this.unit).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-unit')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableUnits.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formUnit.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onPutFormUnit () {
      return new Promise(resolve => {
        AdminUnitService.put(this.unit).then(({ data }) => {
          this.$bvModal.hide('modal-unit')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableUnits.localItems, { id: data.unit.id })
            row.unit_name = data.unit.unit_name
            row.active = data.unit.active
            row.updated_at = data.unit.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formUnit.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
